<template>
  <div class="fd-collapsible" :class="{ active: value }">
    <!-- Title -->
    <div
      class="
        collapsible--handle
        d-flex
        justify-content-between
        align-items-center
        cursor-pointer
        p-1
      "
      @click="toggleCollapse"
    >
      <div v-if="!$slots.title" class="title-slot">{{ title }}</div>
      <div v-else class="title-slot">
        <slot name="title"></slot>
      </div>

      <button type="button" class="arrow flat">
        <i class="fas fa-chevron-down"></i>
      </button>
    </div>
    <!-- Panel -->
    <div ref="collapsiblePanel" class="collapsible-panel">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    title: {
      type: String,
      default: ""
    }
  },
  data: function () {
    return {
      isToggling: false
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    toggleCollapse() {
      let vm = this;

      if (!this.isToggling) {
        this.isToggling = true;
        this.$emit("input", !this.value);
        // eslint-disable-next-line no-undef
        $(this.$refs.collapsiblePanel).slideToggle(250, function () {
          // Emit 'toggled' on animation completes
          vm.isToggling = false;
          vm.$emit("toggled");
        });
      }
    }
  }
};
</script>

<style lang="scss">
.fd-collapsible {
  > .collapsible--handle {
    &:hover {
      .arrow {
        i {
          font-size: 1.2em;
          color: #303030;
        }
      }
    }
    .title-slot {
      flex-grow: 1;
    }

    .arrow i {
      transition: 0.2s;
    }
  }
  > .collapsible-panel {
    display: none;
  }

  &.active {
    > .collapsible--handle {
      .arrow i {
        transform: rotate(180deg);
      }
    }

    > .collapsible-panel {
      display: block;
    }
  }
}
</style>
